<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'guanli_info', 'guanli_time'],
        data() {
            return {
                left_list: [],
                left_search: '',
                right_list: [],
                right_search: '',
                all_check: false,
                all_check_r: false,
                allfactorData: [],
                selectfactorData: [],
                sort_info: {
                    data_name: 1,
                    data_sort: 1,
                },
                sort_info1: {
                    data_name: 1,
                    data_sort: 1,
                },
                page_show: false,
            }
        },
        watch: {
            all_check() {
                this.left_list.map((item, key) => {
                    this.$set(this.left_list[key], 'check', this.all_check)
                })
            },
            all_check_r() {
                this.right_list.map((item, key) => {
                    this.$set(this.right_list[key], 'check', this.all_check_r)
                })
            },
            $route: {
                handler() {
                    this.info = JSON.parse(JSON.stringify(this.guanli_info))
                    this.left_search = this.$route.query.left_search || '';
                    this.right_search = this.$route.query.right_search || '';
                    this.Factorlist()   //获取所有因子列表
                },
                deep: true
            }
        },
        mounted() {
            this.info = JSON.parse(JSON.stringify(this.guanli_info))
            this.left_search = this.$route.query.left_search || '';
            this.right_search = this.$route.query.right_search || '';
            this.Factorlist()   //获取所有因子列表
            setTimeout(() => {
                this.page_show = true
            }, 100)
        },
        methods: {
            searchClick() {
                if (this.left_search == '') {
                    this.left_list = JSON.parse(JSON.stringify(this.allfactorData))
                } else {
                    let list = [];
                    this.allfactorData.map((item) => {
                        if (item.name.indexOf(this.left_search) !== -1) {
                            list.push(item)
                        } else {
                            if (item.chemical.indexOf(this.left_search) !== -1) {
                                list.push(item)
                            }
                        }
                    })
                    this.left_list = list
                }
            },
            makeSearchLeft() {
                if (this.left_search == '') {
                    this.left_list = JSON.parse(JSON.stringify(this.allfactorData))
                } else {
                    let list = [];
                    this.allfactorData.map((item) => {
                        if (item.name.indexOf(this.left_search) !== -1) {
                            list.push(item)
                        } else {
                            if (item.chemical.indexOf(this.left_search) !== -1) {
                                list.push(item)
                            }
                        }
                    })
                    this.left_list = list
                }
            },
            makeSearchRight() {
                if (this.right_search == '') {
                    this.right_list = JSON.parse(JSON.stringify(this.selectfactorData))
                } else {
                    let list = [];
                    this.selectfactorData.map((item) => {
                        if (item.name.indexOf(this.right_search) !== -1) {
                            list.push(item)
                        } else {
                            if (item.chemical.indexOf(this.right_search) !== -1) {
                                list.push(item)
                            }
                        }
                    })
                    this.right_list = list
                }
            },
            Factorlist(data_name = 1, data_sort = 1) {
                this.$sa0.post({
                    url: this.$api('剔除已经选中的因子列表'),
                    data: {
                        det_method_id: this.info.id,
                        data_name: data_name,
                        data_sort: data_sort,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.allfactorData = response.data.list.map((item) => {
                                item.check = false
                                return item
                            })
                            this.makeSearchLeft()
                            this.Get_detmethodfactoryByid()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Get_detmethodfactoryByid(data_name = 1, data_sort = 1) {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfactoryByid'),
                    data: {
                        det_method_id: this.info.id,
                        data_name: data_name,
                        data_sort: data_sort,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.selectfactorData = response.data.list.map((item) => {
                                return item;
                            })
                            this.makeSearchRight()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            allrightclick() {
                let ids = []
                this.left_list.map((item) => {
                    ids.push(Number(item.id))
                })
                this.selectfactorData.map((item) => {
                    ids.push(Number(item.id))
                })
                this.selectrightclick(ids, 1)
            },
            selectleftclick() {
                let ids = []
                let del_ids = []
                this.right_list.map((item) => {
                    if (!item.check) {
                        ids.push(Number(item.id))
                    } else {
                        del_ids.push(Number(item.id))
                    }
                })
                this.selectfactorData.map((item) => {
                    if (del_ids.indexOf(Number(item.id)) === -1) {
                        ids.push(Number(item.id))
                    }
                })
                this.selectrightclick(ids, 2)
            },
            allleftclick() {
                this.selectrightclick([], 2)
            },
            getPushIds() {
                let ids = []
                this.left_list.map((item) => {
                    if (item.check) ids.push(Number(item.id))
                })
                this.selectfactorData.map((item) => {
                    if (ids.indexOf(Number(item)) === -1) ids.push(Number(item.id))
                })
                this.selectrightclick(ids, 1)
            },
            selectrightclick(ids, type) {
                let id = []
                ids.map((item) => {
                    if (id.indexOf(item) == -1) {
                        id.push(item)
                    }
                })
                this.$sa0.post({
                    url: this.$api('Set_ablility'),
                    data: {
                        type,
                        det_method_id: this.info.id,
                        factory_ids: id.join(','),
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Factorlist()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            caretnameupclick() {
                this.sort_info.data_name = 2
                this.Factorlist(2, this.sort_info.data_sort)
            },
            caretnamedownclick() {
                this.sort_info.data_name = 1
                this.Factorlist(1, this.sort_info.data_sort)
            },
            caretcalupclick() {
                this.sort_info.data_sort = 2
                this.Factorlist(this.sort_info.data_name, 2)
            },

            caretcaldownclick() {
                this.sort_info.data_sort = 1
                this.Factorlist(this.sort_info.data_name, 1)
            },


            caretnameupclick1() {
                this.sort_info1.data_name = 2
                this.Get_detmethodfactoryByid(2, this.sort_info1.data_sort)
            },
            caretnamedownclick1() {
                this.sort_info1.data_name = 1
                this.Get_detmethodfactoryByid(1, this.sort_info1.data_sort)
            },
            caretcalupclick1() {
                this.sort_info1.data_sort = 2
                this.Get_detmethodfactoryByid(this.sort_info1.data_name, 2)
            },

            caretcaldownclick1() {
                this.sort_info1.data_sort = 1
                this.Get_detmethodfactoryByid(this.sort_info1.data_name, 1)
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            onSearch(value) {
                console.log(value);
            },
        }
    }
</script>
<template>
    <div>
        <a-drawer title="检测项目因子管理" width="70%" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div class="cscontainer">
                <div class="csconleft">
                    <a-input-search v-model="left_search" placeholder="请输入因子名称" @change="makeSearchLeft" enter-button @search="makeSearchLeft"/>
                    <div class="csconleft-mid">
                        <table class="layui-table">
                            <thead>
                            <tr>
                                <th>
                                    <a-checkbox v-model="all_check"></a-checkbox>
                                </th>
                                <th><span @click="caretnameupclick()" v-if="sort_info.data_name===1">名称</span>
                                    <span @click="caretnamedownclick()" v-if="sort_info.data_name===2">名称</span>
                                    <a-icon type="caret-up" style="color: #999999" @click="caretnameupclick()" v-if="sort_info.data_name===1"/>
                                    <a-icon type="caret-down" style="color: #999999; margin-top: -5px" @click="caretnamedownclick()" v-if="sort_info.data_name===2"/>
                                </th>
                                <th><span @click="caretcalupclick()" v-if="sort_info.data_sort===1">化学式</span>
                                    <span @click="caretcaldownclick()" v-if="sort_info.data_sort===2">化学式</span>
                                    <a-icon type="caret-up" style="color: #999999" @click="caretcalupclick()" v-if="sort_info.data_sort===1"/>
                                    <a-icon type="caret-down" style="color: #999999; margin-top: -5px" @click="caretcaldownclick()" v-if="sort_info.data_sort===2"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,key) in left_list" :key="key">
                                <td>
                                    <a-checkbox v-model='item.check'></a-checkbox>
                                </td>
                                <td>{{item.name}}</td>
                                <td>{{item.chemical}}</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <div class="csconmid">
                    <div class="csconmid-btn">
                        <div class="csconmid-btn-b" @click="allrightclick()">
                            <a-icon type="double-right"/>
                        </div>
                        <div class="csconmid-btn-b" @click="getPushIds()">
                            <a-icon type="right"/>
                        </div>
                        <div class="csconmid-btn-b" @click="selectleftclick()">
                            <a-icon type="left"/>
                        </div>
                        <div class="csconmid-btn-b" @click="allleftclick()">
                            <a-icon type="double-left"/>
                        </div>
                    </div>
                </div>
                <div class="csconleft">
                    <a-input-search v-model="right_search" placeholder="请输入因子名称" @change="makeSearchRight" enter-button @search="makeSearchRight"/>
                    <div class="csconleft-mid">
                        <table class="layui-table">
                            <thead>
                            <tr>
                                <th>
                                    <a-checkbox v-model='all_check_r'></a-checkbox>
                                </th>
                                <th>

                                    <span @click="caretnameupclick1()" v-if="sort_info1.data_name===1">名称</span>
                                    <span @click="caretnamedownclick1()" v-if="sort_info1.data_name===2">名称</span>
                                    <a-icon type="caret-up" style="color: #999999" @click="caretnameupclick1()" v-if="sort_info1.data_name===1"/>
                                    <a-icon type="caret-down" style="color: #999999; margin-top: -5px" @click="caretnamedownclick1()" v-if="sort_info1.data_name===2"/>
                                </th>
                                <th>
                                    <span @click="caretcalupclick1()" v-if="sort_info1.data_sort===1">化学式</span>
                                    <span @click="caretcaldownclick1()" v-if="sort_info1.data_sort===2">化学式</span>
                                    <a-icon type="caret-up" style="color: #999999" @click="caretcalupclick1()" v-if="sort_info1.data_sort===1"/>
                                    <a-icon type="caret-down" style="color: #999999; margin-top: -5px" @click="caretcaldownclick1()" v-if="sort_info1.data_sort===2"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="( item,key) in right_list" :key="key">
                                <td>
                                    <a-checkbox v-model='item.check'></a-checkbox>
                                </td>
                                <td>{{item.name}}</td>
                                <td>{{item.chemical}}</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
        </a-drawer>
    </div>
</template>

<style scoped>
    .cscontainer{ width: 100%; display: flex; height: 100%}

    .csconmid{ width: 100px; position: relative}

    .csconleft{ width: 400px; border: 1px solid #FAFAFA; padding: 10px; overflow-y: auto; height: 800px}

    .csconmid-btn{ position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)}

    .csconmid-btn .csconmid-btn-b{ width: 30px; height: 30px; background: #1890ff; color: #ffffff; text-align: center; line-height: 25px; margin: 10px; border-radius: 2px; cursor: pointer}


</style>
