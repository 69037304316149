<template>
    <div>
        <a-drawer title="创建因子单位" width="840" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="名称">
                        <a-input v-model="form.name" class="w300"/>
                    </a-form-model-item>
                    <a-form-model-item label="排序">
                        <a-input v-model="form.sort" class="w300"/>
                    </a-form-model-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                        <a-checkbox v-model="check_active" @change="onChange1">创建之后选中</a-checkbox>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                        <a-button type="primary" class="mt25" @click="Create_danwei()">创建</a-button>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 4 }"><a @click="authclick">前往因子单位管理页面</a></a-form-item>
                </a-form-model>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */

    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'aaa', 'getStype'],

        data() {
            return {
                check_active:true,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                form: {}
            }
        },
        mounted() {
        },
        methods: {
            authclick() {
                this.$router.push('/jichuziduan/index/?page=1&type=10');
            },
            Create_danwei() {
                this.$sa0.post({
                    url: this.$api('Create_sampletype'),
                    data: {
                        name: this.form.name,
                        sort: this.form.sort,
                        type: 10,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            if(this.check_active){
                                this.getStype(response.data.id)
                            };
                            this.form = {
                                name:'',
                                sort:''
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log('Received values of form: ', values);
                    }
                });
            },
            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },
            onChange1(e) {
                console.log(`checked = ${e.target.checked}`);
            },
            onChange(targetKeys) {
                console.log('Target Keys:', targetKeys);
                this.targetKeys = targetKeys;
            },
            onChecked(_, e, checkedKeys, itemSelect) {
                const {eventKey} = e.node;
                itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
            },
        }
    }
</script>

<style scoped>
    #components-form-demo-validate-other .dropbox{
        height: 180px;
        line-height: 1.5;
        }

    .w300{ width: 300px}

    .mt25{ margin-top: 25px}
</style>
