<script>
    /***
     Name: edit_dir
     Code: sa0ChunLuyu
     Time: 2021/11/10 16:53
     Remark: 编辑文件夹
     */
    export default {
        props: ['create_do','reload'],
        data() {
            return {
                method_info: {
                    id: 0,
                    pid: 0,
                    name: ''
                }
            }
        },
        watch: {
            $route: {
                handler() {
                    this.getItemInfo()
                },
                deep: true
            },
            create_do() {
                this.getItemInfo()
            }
        },
        mounted() {
            this.getItemInfo()
        },
        methods: {
            thisReload(){
                this.getItemInfo()
                this.reload()
            },
            getItemInfo() {
                if (this.create_do === 1) {
                    this.$sa0.post({
                        url: this.$api('Get_detitem_detail'),
                        data: {
                            id: this.$route.params.id
                        }
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.method_info = {
                                    id: 0,
                                    pid: response.data.det_item.id,
                                    name: ''
                                }
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })
                } else {
                    if (Number(this.$route.params.id) !== 0) {
                        this.$sa0.post({
                            url: this.$api('Get_detitem_detail'),
                            data: {
                                id: this.$route.params.id
                            }
                        }).then((response) => {
                            this.$sa0.response({
                                response: response.data,
                                then: (response) => {
                                    this.method_info = response.data.det_item
                                },
                                error: (response) => {
                                    layer.msg(response.message)
                                },
                            })
                        })
                    } else {
                        this.method_info = {
                            id: 0,
                            pid: 0,
                            name: ''
                        }
                    }
                }
            },
            updateDo() {
                this.$sa0.post({
                    url: this.$api('Edit_detitem'),
                    data: {
                        "name": this.method_info.name,
                        "pid": this.method_info.pid,
                        "type": "0",
                        "factor_type": "1",
                        "remark": "",
                        "chemical": "",
                        "factor": "",
                        "id": this.method_info.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功')
                            this.thisReload()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            createDo() {
                this.$sa0.post({
                    url: this.$api('Create_detitem'),
                    data: {
                        "name": this.method_info.name,
                        "pid": this.method_info.pid,
                        "type": "0",
                        "factor_type": "1",
                        "remark": "",
                        "chemical": "",
                        "factor": "",
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('创建成功')
                            this.$router.push({
                                params: {
                                    id: response.data.id
                                }
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            saveClick() {
                if (this.create_do) {
                    this.createDo();
                } else {
                    this.updateDo();
                }
            },
        }
    }
</script>
<template>
    <div>
        <div class="edit_dir_wrapper">
            <div class="input_table_title_wrapper"><b>{{ create_do === 0 ? '编辑' : '新建' }}检测项目分类</b></div>
            <div class="mt-6">
                <div class="mt-3">
                    <a-space>
                        <div class="input_title_wrapper"><b>名称：</b></div>
                        <div>
                            <a-input v-model="method_info.name" class="input_wrapper"></a-input>
                        </div>
                    </a-space>
                </div>
                <div class="mt-3">
                    <a-space>
                        <div class="input_title_wrapper"></div>
                        <div>
                            <button @click="saveClick()" class="layui-btn layui-btn-normal">{{
                                create_do === 0 ? '保存' : '新建'
                                }}
                            </button>
                        </div>
                    </a-space>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .input_title_wrapper {
        width: 120px;
        text-align: right;
        }

    .input_wrapper {
        width: 300px;
        }

    .input_table_title_wrapper {
        font-size: 20px;
        }
    .mt-6{ margin-top:100px;}
</style>
