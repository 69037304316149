<script>
    /***
     Name: list
     Code: sa0ChunLuyu
     Time: 2021/11/15 19:26
     Remark: 列表
     */
    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.child) {
                if (node.child.some(item => item.name.indexOf(key) > -1)) {
                    parentKey = node.id;
                } else if (getParentKey(key, node.child)) {
                    parentKey = getParentKey(key, node.child);
                }
            }
        }
        return parentKey;
    };
    export default {
        data() {
            return {
                searchValue: '',
                expandedKeys: [],
                node_list: [],
                nav_id: 0,
                new: '',
            };
        },
        watch: {
            $route: {
                handler() {
                    this.mountedDo();
                },
                deep: true,
            },
        },
        mounted() {
            this.mountedDo();
        },
        methods: {
            copyclick() {

                this.$sa0.post({
                    url: this.$api('Copy_ablility'),
                    data: {
                        det_item_id: this.nav_id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            onExpand(expandedKeys) {
                this.expandedKeys = expandedKeys;
                this.autoExpandParent = false;
            },
            onChange(e) {
                const value = e.target.value;
                this.searchValue = value
                const expandedKeys = this.node_list
                    .map(() => {
                        return getParentKey(value, this.node_list);
                    })
                    .filter((item, i, self) => item && self.indexOf(item) === i);
                Object.assign(this, {
                    expandedKeys,
                    searchValue: value,
                    autoExpandParent: true,
                });
            },
            navClick(ids) {
                let id = ids.length > 0 ? ids[0] : this.nav_id;
                if (Number(id) === Number(this.$route.params.id)) {
                    this.mountedDo();
                } else {
                    this.$router.push({
                        params: {
                            id: id,
                        },
                    });
                }
            },
            createClick() {
                this.navClick([0]);
            },
            mountedDo() {
                this.nav_id = Number(this.$route.params.id ? this.$route.params.id : 0);
                this.Detitemalllist();
                this.new = this.node_list.name

            },
            Detitemalllist() {
                this.$sa0
                    .post({
                        url: this.$api("Detitemalllist"),
                    })
                    .then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                this.node_list = response.data.list

                                console.log(this.node_list);
                                if (this.nav_id !== 0) this.getItemInfo();
                            },
                            error: (response) => {
                                layer.msg(response.message);
                            },
                        });
                    });
            },
            getItemInfo() {
                this.$sa0
                    .post({
                        url: this.$api("Get_detitem_detail"),
                        data: {
                            id: this.nav_id,
                        },
                    })
                    .then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                let info = response.data.det_item;
                                let k = info.det_item_ids.split(",").map((i) => {
                                    return Number(i)
                                });
                                k.push(this.nav_id);
                                this.expandedKeys = k;
                            },
                            error: (response) => {
                                layer.msg(response.message);
                            },
                        });
                    });
            },

            onSearch(value) {
                console.log(value);
            },
        },
    };
</script>
<template>
    <div class="ppage_left_wrapper">
        <div class="task_tpl_title_wrapper">
            <div class="ml-3">检测项目列表</div>
        </div>
        <div class="srarch-con">
            <input placeholder="搜索" class="srarch-con-mid" @keyup="onChange"/>
            <a-icon type="search" class="searchico"/>
        </div>
        <div class="task_tpl_tree_wrapper ml-3">

            <a-tree :tree-data="node_list" :expandedKeys.sync="expandedKeys" :replaceFields="{ children: 'child', title: 'name', key: 'id'}" :show-line="true" @select="navClick">
                <template slot="name" slot-scope="{ name,type,id }">
                       <span v-if="name.indexOf(searchValue) > -1">
                      {{ name.substr(0, name.indexOf(searchValue)) }}
                      <span style="color: #f50">{{ searchValue }}</span>
                      {{ name.substr(name.indexOf(searchValue) + searchValue.length) }}
                    </span>
                    <span v-else>{{ name }}</span>
                    <div class="copy" v-if="nav_id==id && type===1">
                        <a-button type="" @click="copyclick" size="small">
                            复制
                        </a-button>
                    </div>
                </template>
            </a-tree>

        </div>
        <div class="addbuttonbtn">
            <button @click="createClick()" class="w-full clb">添加分类</button>
        </div>
    </div>
</template>
<style scoped>
    .ppage_left_wrapper{
        margin-left: 0px;
        border: 1px solid #5a5e6620;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 200px;
        border-left: none;
        }

    .task_tpl_title_wrapper{
        height: 36px;
        line-height: 36px;
        background: #ffffff;
        border-bottom: 1px solid #eeeeee;
        font-weight: bold;
        }

    .task_tpl_bottom_wrapper:hover{
        font-weight: bold;
        }

    .task_tpl_bottom_wrapper{
        cursor: pointer;
        position: absolute;
        bottom: 0;
        height: 40px;
        left: 0;
        right: 0;
        line-height: 40px;
        background: #eeeeee;
        }

    .task_tpl_tree_wrapper{
        position: absolute;
        top: 70px;
        bottom: 10px;
        left: 0;
        right: 0;
        overflow-y: auto;
        padding-bottom: 20px;
        }

    .addbuttonbtn{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #1890ff;
        color: #ffffff;
        padding: 10px;
        text-align: center;
        }

    .srarch-con{
        width: 100%;
        padding: 5px;
        position: relative;
        }

    .srarch-con-mid{
        border: 1px solid #f2e7e7;
        width: 100%;
        border-radius: 2px;
        padding: 5px;
        background: #f2f2f2;
        }

    .srarch-con .searchico{
        position: absolute;
        right: 15px;
        top: 15px;
        }

    .ant-tree-treenode-switcher-close{ position: relative; width: 100%; overflow: hidden; overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;}

    .copy{ position: absolute; right: 0px;  top: 5px; z-index: 999999}

    .ant-tree-treenode-switcher-close:hover .copy{ opacity: 1}

</style>
