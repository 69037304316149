<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="sidebar">
                <List v-if="nav_show"></List>
            </div>
            <div class="wrap-container">
                <Item :reload="toReload"></Item>
            </div>
        </div>
    </div>
</template>
<script>
    import List from './list/index.vue'
    import Item from './item/item.vue'
    export default {
        name: "caijiguanli",
        components: {List, Item},
        data() {
            return {
                nav_show: true
            }
        },
        mounted() {
        },
        methods: {
            toReload() {
                this.nav_show = false
                setTimeout(() => {
                    this.nav_show = true
                })
            }
        }
    }
</script>

<style scoped>
    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .sidebar{ width: 200px; display: flex; flex-direction: column}

    .wrap-container{ flex: 1; overflow-y: auto;}
</style>
