<template>
    <!--添加分类-->
    <!-- 创建、编辑分类 -->
    <div class="createsmaplewrap1">
        <div class="createsmaple" style="width: 1000px">
            <div v-if="create_do === 0 && item_type === 0" class=" button_wrapper">
                <a-button type="primary" @click="createDir()">创建分类</a-button>
                <a-button type="primary" @click="createItem()" class="ml20">创建检测能力</a-button>
                <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" style="float: right" @confirm="delThis">
                    <template slot="title"> 确认删除该项？</template>
                    <a-button type="danger" class="ml20"> 删除</a-button>
                </a-popconfirm>
            </div>
        </div>
        <div>
            <div v-if="item_type1 === 1">
                <CreatDir1></CreatDir1>
            </div>
            <div v-else>
                <CreatItem v-if="item_type === 1" :create_do="create_do" :reload="reload"></CreatItem>
                <EditDir v-if="item_type === 0" :create_do="create_do" :reload="reload"></EditDir>
            </div>
        </div>
    </div>
</template>
<script>
    import CreatItem from "./creatitem/creatitem.vue";
    import EditDir from "./creatdir/creatdir.vue";
    import CreatDir1 from "./creatdir1/creatdir2.vue";
    export default {
        components: {CreatItem, EditDir,CreatDir1},
        props: ["reload"],
        name: "item",
        data() {
            return {
                item_info: false,
                method_id: 0,
                item_type: 0,
                item_type1:0,
                create_do: 1,
                formInline: {},
                labelCol: {span: 4},
                wrapperCol: {span: 14},

            };
        },
        watch: {
            $route: {
                handler() {
                    this.mountedDo();
                },
                deep: true,
            },
        },
        mounted() {
            this.mountedDo();
        },
        methods: {
            mountedDo() {
                this.method_id = 0;
                this.method_id = Number(
                    this.$route.params.id ? this.$route.params.id : 0
                );
                this.item_type = 0;
                this.item_type1 = 0;
                if (this.method_id !== 0) {
                    this.getMethodInfo();
                } else {
                    this.create_do = 1;
                }
            },
            getMethodInfo() {
                console.log('getMethodInfo', this.method_id)
                this.$sa0
                    .post({
                        url: this.$api("Get_detitem_detail"),
                        data: {
                            id: this.method_id,
                        },
                    })
                    .then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                console.log(JSON.stringify(response.data.det_item))
                                let info = response.data.det_item;
                                this.item_info = info;
                                this.item_type = Number(info.type);
                                this.create_do = 0;
                            },
                            error: (response) => {
                                layer.msg(response.message);
                            },
                        });
                    });
            },
            toBack() {
                this.create_do = 0;
                this.item_type = this.item_info.type;
            },
            createDir() {
                this.create_do = 1;
                this.item_type = 0;
            },
            createItem() {
                this.create_do = 1;
                this.item_type1 = 1;
                //  创建 弹框
                // this.createItem1(name, type, document_id, is_qualify)
            },
            // createItem1(name, type, document_id, is_qualify) {
            //     this.$sa0.post({
            //         url: this.$api('Create_ablility'),
            //         data: {
            //             "det_item_id": this.$route.params.id,
            //             "det_item_name": name,
            //             "factor_type": type,
            //             "status": "1",
            //             "document_id": document_id,
            //             "is_qualify": is_qualify
            //         }
            //     }).then((response) => {
            //         this.$sa0.response({
            //             response: response.data,
            //             then: (response) => {
            //                 layer.msg('创建成功')
            //             },
            //             error: (response) => {
            //                 layer.msg(response.message)
            //             },
            //         })
            //     })
            // },
            delThis() {
                this.$sa0.post({
                    url: this.$api('Del_detitem'),
                    data: {
                        id: this.method_id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.$router.push('/taskability/taskability/0')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

        },
    };
</script>

<style scoped>
    .createsmapletit{
        font-size: 20px;
        font-weight: bold;
        }

    .ml20{
        margin-left: 20px;
        }

    .mt20{
        margin-top: 20px;
        }

    .w200{
        width: 200px;
        }

    .button_wrapper{
        position: absolute;
        width: 1000px;
        text-align: right; top: 40px; text-align: left;
        background: #fafafa; padding: 10px 20px;
        }

    .edit_table_wrapper{
        position: absolute;
        left: 280px;
        right: 10px;
        bottom: 0;
        top: 0;
        }

    .creatlist{ margin-top: 100px}
</style>
