<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time',],
        data() {
            return {
                equipmentList:'',
                value: 1,
                value1: 1,
                fangfaname: [],
                info: {},
            }
        },
        mounted() {
            this.info = JSON.parse(JSON.stringify(this.edit_info))
            this.Documentlist()  //获取文档所有列表
            console.log('11111111111', JSON.stringify(this.edit_info))
            this.Sampletypelist()
        },
        methods: {

            //w设备列表
            Sampletypelist() {
                this.$sa0.file({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 8,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            if (response.code === 0) {
                                this.equipmentList = response.data.list
                                //this.enterResultInfo.equipment = response.data.list[0].id
                            }
                        }
                    })
                })
            },
            // 获获取文档所有列表
            Documentlist() {
                this.$sa0.post({
                    url: this.$api('Documentlist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.fangfaname = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            doClick() {
                let data = {
                    det_item_id: this.info.det_item_id,
                    det_item_current_id: this.info.det_item_current_id,
                    det_item_name: this.info.det_item_name,
                    factor_type: this.info.factor_type,
                    status: this.info.status,
                    document_id: this.info.document_id,
                    is_qualify: this.info.is_qualify,
                    status_method: this.info.status_method,
                    apparatustype_id: this.info.equipment,//w
                }
                console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    // url: Number(this.info.id) !== 0 ? this.$api('Create_ablility') : this.$api('Edit_ablility'),
                    url: this.$api('Create_ablility'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                            console.log('231', JSON.stringify(response.data))
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            adddoClick() {
                let data = {
                    det_item_id: this.info.det_item_id,
                    det_item_current_id: this.info.det_item_current_id,
                    det_item_name: this.info.det_item_name,
                    factor_type: this.info.factor_type,
                    status: this.info.status,
                    document_id: this.info.document_id,
                    is_qualify: this.info.is_qualify,
                    status_method: this.info.status_method,
                    apparatustype_id: this.info.equipment,//w
                }
                console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Create_ablility'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.info = {
                                document_id: '',
                                is_qualify: '',
                                status_method: '',
                                type: 'tianjia',
                                det_item_id: this.info.det_item_id,
                                det_item_current_id: this.info.det_item_current_id,
                                det_item_name: this.info.det_item_name,
                                factor_type: this.info.factor_type,
                                status: this.info.status,
                            }
                            console.log('231', JSON.stringify(response.data))
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            //编辑
            doClick1() {
                let data = {
                    document_id: this.info.document_id,
                    is_qualify: this.info.is_qualify,
                    status_method: this.info.status_method,
                    det_method_id: this.info.id,
                    apparatustype_id: this.info.apparatustype_name,//w
                }
                this.$sa0.post({
                    url: this.$api('Edit_ablility'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                            console.log('231ss', JSON.stringify(response.data))
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },

            handleChange(value) {
                console.log(`selected ${value}`);
            },
            handleBlur() {
                console.log('blur');
            },
            handleFocus() {
                console.log('focus');
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
        }
    }
</script>
<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数 title="添加检出限模板"-->
        <a-drawer   :title="`${info.type=== 'tianjia'?'新建':'编辑'}检测方法`" width="100%" height="50%" placement="bottom" :closable="true" :visible="visible" @close="onClose">
            <div>
                <table class="layui-table">
                    <tbody>
                    <tr>
                        <td>检测方法名称</td>
                        <td>
                            <a-select v-model="info.document_id" show-search placeholder="请选择" option-filter-prop="children" style="width: 300px" :filter-option="filterOption" @focus="handleFocus" @blur="handleBlur" @change="handleChange">
                                <a-select-option v-for="(item,key ) in fangfaname" :key="key" :value="item.id">
                                    {{ item.serial_code}}/{{ item.name}}
                                </a-select-option>
                            </a-select>
                        </td>
                        <td>检测设备</td>
                        <td>
                            <a-select v-model="info.apparatustype_name" style="width: 300px">
                                <a-select-option v-for="item in equipmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                            </a-select>
                        </td>
                        <td>是否需要资质</td>
                        <td>
                            <a-radio-group v-model="info.is_qualify">
                                <a-radio :value="1">
                                    是
                                </a-radio>
                                <a-radio :value="2">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </td>
                        <td>执行</td>
                        <td>
                            <a-radio-group v-model="info.status_method">
                                <a-radio :value="1">
                                    现行
                                </a-radio>
                                <a-radio :value="2">
                                    废止
                                </a-radio>
                            </a-radio-group>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="mt20">
                    <a-button type="primary" @click="doClick()" v-if="info.type=='tianjia'">
                        保存并关闭
                    </a-button>
                    <a-button type="primary" @click="doClick1()" v-if="info.type=='bianji'">
                        保存并关闭
                    </a-button>
                    <a-button class="ml20" @click="adddoClick()" v-if="info.type=='tianjia'">保存并继续添加下一个</a-button>
                </div>
            </div>
        </a-drawer>
    </div>
</template>
<style scoped>
    .w300{ width: 300px}

    .ml20{ margin-left: 20px}

    .mt20{ margin-top: 20px}

</style>
