<template>
    <div class="rightcontainer">
        <div class="tabletop">
            <div style="display: flex; justify-content: space-between">
                <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
                    <a-form-model-item label="检测项目名称">
                        <a-input v-model="formInline.name" class="w200" @blur="onclickswitch(isactive)"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="因子类型">
                        <a-radio-group name="radioGroup" :default-value="1" v-model="formInline.factor_type">
                            <a-radio :value="1" v-if="formInline.factor_type==1">
                                单因子
                            </a-radio>
                            <a-radio :value="2" v-if="formInline.factor_type==2">
                                多因子
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="状态">
                        <a-switch @change="onclickswitch(isactive)" v-model="formInline.status"/>
                    </a-form-model-item>
                </a-form-model>
                <div class="mt10">
                    <a-button @click="showDrawer1({

                    type:'tianjia',
            det_item_id:formInline.pid,
            det_item_current_id:$route.params.id,
            det_item_name:formInline.name,
            factor_type:Number(formInline.factor_type),
             status:formInline.status ? '1' : '2',
            document_id:'',
            is_qualify:2,
            status_method:1,
            },false)" type="primary">添加检测方法
                    </a-button>
                    <a-button type="primary" class="ml10" @click="showDrawer1({
                    ...isactive,
                    type:'bianji',
                    })">编辑
                    </a-button>
                    <a-button type="primary" class="ml10" @click="xianxing(isactive)">现行</a-button>
                    <a-button type="danger" class="ml10" @click="feizhi(isactive)">废止</a-button>
                </div>
            </div>
            <div id="elec_table">
                <div class="table-head">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th style="width: 20%">方法名称</th>
                            <th style="width: 10%">执行标准</th>
                            <th style="width: 30%">标准文档</th>
                            <th style="width: 15%">检测设备</th>
                            <th style="width: 10%">是否需要资质</th>
                            <th style="width: 5%">执行</th>
                            <th style="width: 10%">检测因子</th>
                        </tr>
                        </thead>
                    </table>
                </div>
                <div class="bodyBox">
                    <table class="layui-table selectlu" lay-size="sm">
                        <tbody>
                        <tr v-for="( item,key) in tableData" :key="key" :class="isactive.id == item.id ? 'active' : '' " @click='onclick(item)'>
                            <td style="width: 20%">{{item.name}}</td>
                            <td style="width: 10%">{{item.serial_code}}</td>
                            <td style="width: 30%">{{item.document_file}}</td>
                            <td style="width: 15%">{{item.apparatustype_name}}</td>
                            <td style="width: 10%">{{wordType(item.is_qualify)}}</td>
                            <td style="width: 5%">{{wordType1(item.status_method)}}</td>
                            <td style="width: 10%">
                                <a-button type="link" class="ml5" @click="showDrawer(item)">管理检测因子</a-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="tablebottom">
            <div class="tablebottominfo">
                <div class="tablebottominfotitle">
                    检出限模板
                    <a-button type="primary" size="small" class="fr" @click="addmoban()">添加</a-button>
                </div>
                <div class="tablebottominfo-con">
                    <div class="tablebottominfo-con-list" v-for="( iteminfo ,key) in mobanData" :key="key" :class="isactive1.id == iteminfo.id ? 'active' : '' " @click='onclick1(iteminfo)'>
                        <div class="tablebottominfo-con-list-name">
                            <input v-show="edit_input_show ==  iteminfo.id" placeholder="模板名称" v-model="iteminfo.name" class="noborder" style="padding: 4px 11px;font-size: 14px; height: 30px; line-height: 30px" @blur="onclickinput(isactive1)"/>
                            <span v-show="edit_input_show !=  iteminfo.id" style=" padding: 4px 11px;font-size: 14px;  height: 30px; line-height: 30px">{{ iteminfo.name }}</span>
                        </div>
                        <div>
                            <a-tooltip placement="right" trigger="click">
                                <template slot="title">
                                    <ul class="tooltipul">
                                        <li @click="CopydetmethodtemplateCLICK()">创建副本</li>
                                        <!--                                        <li @click="DeldetmethodtemplateCLICK(isactive1)">删除此模板-->
                                        <li>
                                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" style="float: right" @confirm="DeldetmethodtemplateCLICK()">
                                                <template slot="title"> 确认删除该项？</template>
                                                删除此模板
                                            </a-popconfirm>
                                        </li>
                                    </ul>
                                </template>
                                <a-icon type="more" class="moreico"/>
                            </a-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tablebottomconcontainer" style="width: 800px">
                <div id="elec_table" style="height: 420px">
                    <div class="table-head">
                        <table class="layui-table" lay-size="sm">
                            <thead>
                            <tr>
                                <th style="width: 60px;" @click="yinziclickup(0)">
                                    <span class="fl">检测因子</span>
                                    <span class="ant-table-column-sorter fl">
                                    <a-icon type="caret-up" class="jiantou" :class="[ordertype === 0 && sortrule === 'asc' ?'classname':'']"/>
                                    <a-icon type="caret-down" class="jiantou" :class="[ordertype === 0 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                                    </span>
                                </th>
                                <th style="width: 50px;" @click="yinziclickup(1)">
                                    <span class="fl">化学式</span>
                                    <span class="ant-table-column-sorter fl">
<!--                                    <a-icon type="caret-up" class="jiantou" :class="[order_icon1[0] === 1 && order_icon1[1] === 0 ?'classname':'']" @click="calclickup()"/>-->
                                        <!--                                    <a-icon type="caret-down" class="jiantou" :class="[order_icon1[0] === 1 && order_icon1[1] === 1 ?'classname':'']" style=" margin-top: -5px" @click="calclickdown()"/>-->

                                         <a-icon type="caret-up" class="jiantou" :class="[ordertype === 1 && sortrule === 'asc' ?'classname':'']"/>
                                         <a-icon type="caret-down" class="jiantou" :class="[ordertype === 1 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                                    </span>
                                </th>
                                <th style="width: 80px">
                                    类型
                                </th>
                                <th style="width: 130px">
                                    标准值
                                </th>
                                <th style="width: 70px" @click="yinziclickup(4)">
                                    <span class="fl">单位</span>
                                    <span class="ant-table-column-sorter fl">
<!--                                     <a-icon type="caret-up" class="jiantou" :class="[order_icon4[0] === 1 && order_icon4[1] === 0 ?'classname':'']" @click="danweiclickup()"/>-->
                                        <!--                                    <a-icon type="caret-down" class="jiantou" :class="[order_icon4[0] === 1 && order_icon4[1] === 1 ?'classname':'']" style="margin-top: -5px" @click="danweiclickdown()"/>-->

                                         <a-icon type="caret-up" class="jiantou" :class="[ordertype === 4 && sortrule === 'asc' ?'classname':'']"/>
                                        <a-icon type="caret-down" class="jiantou" :class="[ordertype === 4 && sortrule === 'desc' ?'classname':'']" style=" margin-top: -5px"/>
                                    </span>
                                </th>
                                <th style="width: 70px">操作
                                    <a-button type="primary" lay-size="small" class="ml5" @click="piliangsaveItem()" style="padding: 0px 3px; height: 25px">
                                        批量保存
                                    </a-button>
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="bodyBox" style="height: 383px; margin-top: 6px">
                        <table class="layui-table selectlu" lay-size="sm">
                            <tbody>
                            <tr v-for="( yinziitem ,key) in yinziData" :key="key">
                                <td style="width: 60px">{{yinziitem.factor_name}}</td>
                                <td style="width:50px">{{yinziitem.chemical}}</td>
                                <td style="width: 80px">
                                    <a-select style="width:120px" default-value="请选择" v-model="yinziitem.type">
                                        <a-select-option :value="1">
                                            定量-范围值
                                        </a-select-option>
                                        <a-select-option :value="3">
                                            定量-具体值
                                        </a-select-option>
                                        <a-select-option :value="2">
                                            定性
                                        </a-select-option>
                                    </a-select>
                                </td>
                                <td style="width:130px">
                                    <div v-if="yinziitem.type===1">
                                        <span>下限: </span>
                                        <a-input placeholder="下限" style="width: 67px; " v-model="yinziitem.limit"/>
                                        <span style="margin-left: 5px">上限: </span>
                                        <a-input placeholder="上限" style="width: 67px; " v-model="yinziitem.upper"/>
                                    </div>
                                    <div v-if="yinziitem.type===2">
                                        <a-auto-complete v-model="yinziitem.bz_value" :data-source="dataSource" style="width: 200px" placeholder="请输入" @select="onSelect1" @search="onSearch1" @change="onChange1"/>
                                    </div>
                                    <div v-if="yinziitem.type===3">
                                        <a-select style="width:120px; " default-value="请选择" v-model="yinziitem.bj">
                                            <a-select-option value=">=">
                                                >=
                                            </a-select-option>
                                            <a-select-option value="<=">
                                                <=
                                            </a-select-option>
                                            <a-select-option value=">">
                                                >
                                            </a-select-option>
                                            <a-select-option value="<">
                                                <
                                            </a-select-option>
                                            <a-select-option value="=">
                                                =
                                            </a-select-option>
                                        </a-select>
                                        <a-input v-model="yinziitem.bz_value" style="width: 76px; margin-left: 5px "/>
                                    </div>

                                </td>
                                <td style="width: 70px">
                                    <div style="display: flex;">
                                        <div>
                                            <a-auto-complete v-model="yinziitem.name" :data-source="yinziitem.factorunits_list" style="width: 100px" placeholder="" @select="onSelect" @search="onSearch" @change="(e)=>{onChange(e,key)}"/>
                                        </div>
                                    </div>
                                </td>
                                <td style="width: 70px">
                                    <a-button type="link" lay-size="small" class="ml5" @click="saveItem(yinziitem)" style="padding: 0px 0px">
                                        保存
                                    </a-button>
                                    <a-button type="link" lay-size="small" class="ml5" @click="DelItem(yinziitem)" style="padding: 0px 4px">
                                        移除
                                    </a-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="guanli_info && guanli_show">
            <Jianceyinzi :guanli_time="guanli_time" :guanli_info="guanli_info" :close="onCloseDrawer" :visible="drawer_visible"></Jianceyinzi>
        </div>
        <div v-if="edit_info && edit_show">
            <Creatmoban :edit_time="edit_time" :edit_info="edit_info" :close="onCloseDrawer1" :visible="drawer_visible1"></Creatmoban>
        </div>
        <Adddanwei :getStype="getStype" :close="onCloseDrawer2" :visible="drawer_visible2"></Adddanwei>
    </div>
</template>
<script>

    import Jianceyinzi from '../Jianceyinzi/Jianceyinzi';
    import Creatmoban from '../creatmoban/creatmoban';
    import Adddanwei from '../adddanwei/index';

    export default {
        props: ['create_do', 'reload'],
        components: {
            Jianceyinzi, Creatmoban, Adddanwei
        },
        data() {
            return {

                new_arr: [],
                factor_unit_id: '',
                value: '',
                dataSource: [],
                order_icon: [0, 0],
                order_icon1: [0, 0],
                order_icon2: [0, 0],
                order_icon3: [0, 0],
                order_icon4: [0, 0],
                create_u_key: -1,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                formInline: {},
                edit_input_show: 0,
                drawer_visible: false,
                drawer_visible1: false,
                drawer_visible2: false,
                tableData: [],

                edit_show: false,
                edit_info: false,
                edit_time: 0,

                guanli_show: false,
                guanli_info: false,
                guanli_time: 0,

                det_item: [],
                mobanData: [],
                iteminfo: [],
                yinziData: [],
                factorunits: [],
                isactive: false,
                isactive1: false,
                ordertype: 0,
                sortrule: 'asc',
                sort_info: {
                    sortrule: 'desc',
                },

            }
        },


        watch: {
            value(val) {
                console.log('value', val);
            },
            $route: {
                handler() {
                    this.formInline.factor_type = Number(this.formInline.factor_type)
                    this.Get_ablility()  //根据检测项目Id获取检测方法
                    this.getItemInfo()
                    this.Sampletypelist() //获取基础字段全部列表
                },
                factor_unit_id(factor_unit_id) {
                    console.log('value', factor_unit_id);
                },
                deep: true
            }
        },
        mounted() {
            this.formInline.factor_type = Number(this.formInline.factor_type)
            this.Get_ablility()  //根据检测项目Id获取检测方法
            this.getItemInfo()
            // this.Sampletypelist() //获取基础字段全部列表
            this.getStype()
        },
        methods: {


            onSearch1(searchText) {
                this.dataSource = !searchText ? [] : [searchText, searchText.repeat(2), searchText.repeat(3)];
            },
            onSelect1(value) {
                console.log('onSelect1', value);
            },
            onChange1(value) {
                console.log('onChange1', value);
            },


            onSearch(factor_unit_id) {
                this.factorunits.map((item) => {
                    if (item.name.indexOf(factor_unit_id) != -1) {
                        this.new_arr.push(item)
                    }
                })
            },
            onSelect(factor_unit_id) {
                console.log('onSelect', factor_unit_id);
            },
            onChange(search, key) {
                if (search != '') {
                    let arr = this.factorunits;
                    let new_arr = [];
                    arr.map((item) => {
                        if (item.name.indexOf(search) != -1) {
                            new_arr.push(item.name)
                        }
                    })
                    this.$set(this.yinziData[key], 'factorunits_list', new_arr)
                } else {
                    this.$set(this.yinziData[key], 'factorunits_list', this.factorunits.map((item) => {
                        return item.name
                    }))
                }
                // console.log('onChange', factor_unit_id);
            },


            yinziclickup(ordertype) {
                if (this.ordertype !== ordertype) {
                    this.ordertype = ordertype
                    this.sortrule = 'asc'
                } else {
                    if (this.sortrule === 'asc') {
                        this.sortrule = 'desc'
                    } else {
                        this.sortrule = 'asc'
                    }
                }
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                        ordertype: this.ordertype,
                        sortrule: this.sortrule,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // yinziclickdown() {
            //     this.$sa0.post({
            //         url: this.$api('Get_detmethodfacotry'),
            //         data: {
            //             det_method_id: this.isactive.id,
            //             det_method_template_id: this.isactive1.id,
            //             ordertype: 1,
            //             sortrule: 'asc',
            //         },
            //     }).then((response) => {
            //         this.$sa0.response({
            //             response: response.data,
            //             then: (response) => {
            //                 this.orderIcon(1, 1)
            //                 this.orderIcon1(0, 0)
            //                 this.orderIcon2(0, 0)
            //                 this.orderIcon3(0, 0)
            //                 this.orderIcon4(0, 0)
            //                 this.yinziData = response.data.list.map((item) => {
            //                     item.factorunits_list = []
            //                     return item;
            //                 })
            //             },
            //             error: (response) => {
            //                 layer.msg(response.message)
            //             },
            //         })
            //     })
            //
            // },


            calclickup() {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                        ordertype: 2,
                        sortrule: 'desc',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.orderIcon1(1, 0)
                            this.orderIcon(0, 0)
                            this.orderIcon2(0, 0)
                            this.orderIcon3(0, 0)
                            this.orderIcon4(0, 0)
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            calclickdown() {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                        ordertype: 2,
                        sortrule: 'asc',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.orderIcon1(1, 1)
                            this.orderIcon(0, 0)
                            this.orderIcon2(0, 0)
                            this.orderIcon3(0, 0)
                            this.orderIcon4(0, 0)
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            fwclickup() {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                        ordertype: 3,
                        sortrule: 'desc',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.orderIcon2(1, 0)
                            this.orderIcon(0, 0)
                            this.orderIcon1(0, 0)
                            this.orderIcon3(0, 0)
                            this.orderIcon4(0, 0)
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            fwclickdown() {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                        ordertype: 3,
                        sortrule: 'asc',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.orderIcon2(1, 1)
                            this.orderIcon(0, 0)
                            this.orderIcon1(0, 0)
                            this.orderIcon3(0, 0)
                            this.orderIcon4(0, 0)
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            fwoneclickup() {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                        ordertype: 4,
                        sortrule: 'desc',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                            this.orderIcon3(1, 0)
                            this.orderIcon(0, 0)
                            this.orderIcon1(0, 0)
                            this.orderIcon2(0, 0)
                            this.orderIcon4(0, 0)

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            fwoneclickdown() {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                        ordertype: 4,
                        sortrule: 'asc',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.orderIcon3(1, 1)
                            this.orderIcon(0, 0)
                            this.orderIcon1(0, 0)
                            this.orderIcon2(0, 0)
                            this.orderIcon4(0, 0)
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            danweiclickup() {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                        ordertype: 5,
                        sortrule: 'desc',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.orderIcon4(1, 0)
                            this.orderIcon(0, 0)
                            this.orderIcon2(0, 0)
                            this.orderIcon3(0, 0)
                            this.orderIcon1(0, 0)
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            danweiclickdown() {
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                        ordertype: 5,
                        sortrule: 'asc',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.orderIcon4(1, 1)
                            this.orderIcon(0, 0)
                            this.orderIcon2(0, 0)
                            this.orderIcon3(0, 0)
                            this.orderIcon1(0, 0)
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            onclick(item) {
                this.isactive = item
                this.isactive1 = false
                this.Get_detmethodtemplate()
            },
            onclick1(iteminfo) {
                if (this.isactive1 && this.isactive1.id == iteminfo.id) {
                    this.edit_input_show = iteminfo.id
                } else {
                    this.isactive1 = iteminfo
                    this.Get_detmethodfacotry()
                }
            },
            addmoban() {
                this.$sa0.post({
                    url: this.$api('Create_detmethodtemplate'),
                    data: {
                        det_method_id: this.isactive.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.mobanData = response.data.list
                            this.Get_detmethodtemplate()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Get_detmethodtemplate() {
                let id = this.$route.params.id;
                console.log(id)
                this.$sa0.post({
                    url: this.$api('Get_detmethodtemplate'),
                    data: {
                        det_method_id: this.isactive.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.mobanData = response.data.list
                            this.yinziData = []
                            if (!this.isactive1 && this.mobanData.length > 0) {
                                this.onclick1(this.mobanData[0])
                            } else {
                                this.Get_detmethodfacotry() // 右
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 获取基础字段全部列表
            getStype(new_id) {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 10
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.factorunits = response.data.list
                            console.log(this.create_u_key, 'this.create_u_key')
                            if (this.create_u_key !== -1) {
                                this.$set(this.yinziData[this.create_u_key], 'factor_unit_id', Number(new_id))
                            }
                            this.onCloseDrawer2()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 获取模板因子列表
            Get_detmethodfacotry() {
                // let id = this.$route.params.id;
                // console.log(id)
                this.$sa0.post({
                    url: this.$api('Get_detmethodfacotry'),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.yinziData = response.data.list.map((item) => {
                                item.factorunits_list = []
                                return item;
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 修改因子
            saveItem(yinziitem) {
                //console.log('123456', JSON.stringify(yinziitem.name))
                let arr = this.factorunits;
                let did = 0
                arr.map((item) => {
                    if (item.name === yinziitem.name) did = item.id
                })
                if (did !== 0) {
                    this.saveThis(yinziitem, did);
                    // this.piliangsaveItem(yinziitem, did)
                } else {
                    // 新建单位
                    this.$sa0.post({
                        url: this.$api('Create_sampletype'),
                        data: {
                            name: yinziitem.name,
                            type: 10,
                        }
                    }).then((response) => {
                        this.$sa0.response({
                            response: response.data,
                            then: (response) => {
                                did = response.data.id
                                this.saveThis(yinziitem, did);
                                // this.piliangsaveItem(yinziitem, did)
                            },
                            error: (response) => {
                                layer.msg(response.message)
                            },
                        })
                    })

                }
            },

            saveThis(yinziitem, did) {
                let data = {
                    det_method_facotry_id: yinziitem.id,
                    upper: yinziitem.upper,
                    limit: yinziitem.limit,
                    factor_unit_id: did,
                    type: yinziitem.type,
                    bz_value: yinziitem.bz_value,
                    bj: yinziitem.bj,
                };
                console.log('luxia', JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Set_detmethodfacotry'),
                    data: data
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功')
                            this.Get_detmethodfacotry()
                            this.getStype()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            piliangsaveItem() {
                let content = []
                let factorunits = this.factorunits;
                this.yinziData.forEach(function (item, index) {
                    // yinziitem.name
                    let temp = {}
                    temp.det_method_facotry_id = item.id
                    temp.upper = item.upper
                    temp.limit = item.limit
                    temp.type = item.type
                    temp.bz_value = item.bz_value
                    temp.bj = item.bj
                    factorunits.map(i=>{
                        if(i.name===item.name) {
                            temp.factor_unit_id =i.id
                        }
                    })
                    content.push(temp)
                })


                let data = {
                    content
                };
                this.$sa0.post({
                    url: this.$api('Set_detmethodfacotry_all'),
                    data: data
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('保存成功')
                            this.Get_detmethodfacotry()
                            this.getStype()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            // 修改因子
            // saveItem(yinziitem) {
            //
            //     let data = {
            //         det_method_facotry_id: yinziitem.id,
            //         upper: yinziitem.upper,
            //         limit: yinziitem.limit,
            //         factor_unit_id: this.new_arr,
            //         type: yinziitem.type,
            //     };
            //     console.log('luxia', JSON.stringify(data))
            //     this.$sa0.post({
            //         url: this.$api('Set_detmethodfacotry'),
            //         data: data
            //     }).then((response) => {
            //         this.$sa0.response({
            //             response: response.data,
            //             then: (response) => {
            //                 layer.msg('修改成功')
            //                 this.Get_detmethodfacotry()
            //             },
            //             error: (response) => {
            //                 layer.msg(response.message)
            //             },
            //         })
            //     })
            // },

            // DelItem

            DelItem(yinziitem) {
                let data = {
                    det_method_facotry_id: yinziitem.id,
                    det_method_id: this.isactive.id
                };
                console.log('luxia', JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Del_detmethodfacotry'),
                    data: data
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('移除成功')
                            this.Get_detmethodfacotry()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 修改检测项目
            onclickswitch() {
                this.$sa0.post({
                    url: this.$api("Edit_det_item"),
                    data: {
                        det_item_id: this.$route.params.id,
                        name: this.formInline.name,
                        status: this.formInline.status ? '1' : '2',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功');
                            this.Get_ablility()
                            this.reload()
                            // console.log('1111', JSON.stringify(response.data))
                        },
                        error: (response) => {
                            layer.msg(response.message);
                        },
                    });
                });

            },

            // 修改模板
            onclickinput() {
                this.$sa0.post({
                    url: this.$api("Edit_detmethodtemplate"),
                    data: {
                        det_method_template_id: this.isactive1.id,
                        name: this.isactive1.name,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.Get_detmethodtemplate()
                            this.Get_detmethodfacotry()
                            console.log(JSON.stringify(response.data))
                        },
                        error: (response) => {
                            layer.msg(response.message);
                        },
                    });
                });
            },

            // 删除模板
            DeldetmethodtemplateCLICK() {
                this.$sa0.post({
                    url: this.$api("Del_detmethodtemplate"),
                    data: {
                        det_method_template_id: this.isactive1.id,
                        det_method_id: this.isactive.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.isactive1 = false
                            setTimeout(() => {
                                this.Get_detmethodtemplate() // 左
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message);
                        },
                    });
                });
            },

            // 复制模板
            CopydetmethodtemplateCLICK() {
                this.$sa0.post({
                    url: this.$api("Copy_detmethodtemplate"),
                    data: {
                        det_method_id: this.isactive.id,
                        det_method_template_id: this.isactive1.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('复制成功');
                            this.isactive1.id = response.data.template_id
                            setTimeout(() => {
                                this.Get_detmethodtemplate() // 左
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message);
                        },
                    });
                });
            },
            // 现行
            xianxing(isactive) {
                this.$sa0.post({
                    url: this.$api("Set_detitem"),
                    data: {
                        det_method_id: isactive.id,
                        status: 1
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功');
                            this.Get_ablility()
                            // console.log('1111', JSON.stringify(response.data))
                        },
                        error: (response) => {
                            layer.msg(response.message);
                        },
                    });
                });
            },
            // 废止
            feizhi(isactive) {
                this.$sa0.post({
                    url: this.$api("Set_detitem"),
                    data: {
                        det_method_id: isactive.id,
                        status: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg('修改成功');
                            this.Get_ablility()
                            // console.log('1111', JSON.stringify(response.data))
                        },
                        error: (response) => {
                            layer.msg(response.message);
                        },
                    });
                });
            },
            // 获取左侧对应详情
            getItemInfo() {
                this.$sa0.post({
                    url: this.$api("Get_detitem_detail"),
                    data: {
                        id: this.$route.params.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.formInline = {
                                name: response.data.det_item.name,
                                factor_type: Number(response.data.det_item.factor_type),
                                status: Number(response.data.det_item.status) === 1,   //数字转成布尔值真
                                pid: response.data.det_item.pid,
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message);
                        },
                    });
                });
            },
            // 根据检测项目Id获取检测方法
            Get_ablility() {
                let id = this.$route.params.id;
                console.log(id)
                this.$sa0.post({
                    url: this.$api('Get_ablility'),
                    data: {
                        det_item_id: id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tableData = response.data.list
                            if (this.isactive) {
                                this.tableData.map((item) => {
                                    if (Number(this.isactive.id) === Number(item.id)) {
                                        this.onclick(item)
                                    }
                                })
                            }


                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 类型转换
            wordType(status) {
                let type_arr = ['需要', '不需要']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },

            // 类型转换
            wordType1(status) {
                let type_arr = ['现行', '废止']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
                this.Get_ablility()
                // this.getItemInfo()
                this.Get_detmethodfacotry()
            },
            // 定义 打开抽屉时的 函数
            showDrawer(item) {
                this.guanli_show = false
                this.guanli_time = new Date() / 1;
                this.guanli_info = item
                this.drawer_visible = true;
                setTimeout(() => {
                    this.guanli_show = true
                })
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer1() {

                this.drawer_visible1 = false;
                this.Get_ablility()

            },
            // 定义 打开抽屉时的 函数
            showDrawer1(item) {
                console.warn('---------------')
                console.log(JSON.stringify(item))
                console.warn('---------------')


                this.edit_show = false
                this.edit_time = new Date() / 1;
                this.edit_info = item
                this.drawer_visible1 = true;
                setTimeout(() => {
                    this.edit_show = true
                })
                // console.log(item)
            },


            // 定义 关闭抽屉时的 函数
            onCloseDrawer2() {
                this.drawer_visible2 = false;
            },
            // 定义 打开抽屉时的 函数
            showDrawer2(key) {
                let dom = this.$refs[`Select${key}`]
                console.log(dom)
                // this.create_u_key = key
                // this.drawer_visible2 = true;
            },


            handleChange(value) {
                console.log(`selected ${value}`);
            },
            handleBlur() {
                console.log('blur');
            },
            handleFocus() {
                console.log('focus');
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
        }
    }
</script>
<style scoped>
    .mt10{
        margin-top: 10px
        }

    .ml10{
        margin-left: 10px
        }

    .rightcontainer{
        }

    .tabletop{
        background: #FAFAFA;
        padding: 5px 5px 0px 5px;
        }

    .tablebottom{
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        }

    .tablebottominfo{
        width: 250px;
        }

    .tablebottomconcontainer{
        width: 1000px
        }

    .tablebottominfo{
        background: #FAFAFA;
        padding: 5px
        }

    .tablebottominfotitle{
        font-weight: bold
        }

    .fr{
        float: right
        }

    .tablebottominfo-con{
        background: #ffffff;
        margin-top: 5px;
        height: 370px;
        overflow-y: auto
        }

    .tablebottominfo-con-list{
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        padding: 5px;
        }

    .tablebottominfo-con-list.active{
        background: #49a4f9;
        color: #ffffff
        }

    .tablebottominfo-con-list-name{
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        }

    .moreico{
        transform: rotate(-90deg);
        opacity: 0.8;
        font-size: 1.5rem;
        transition: 0.3s all;
        margin-top: 5px;
        cursor: pointer
        }

    .tablebottominfo-con-list-name .noborder{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        }

    .tablebottomconcontainer{
        margin-left: 10px;
        margin-top: -10px;
        }

    .w100{
        width: 100px
        }

    .noborder{
        border: none;
        background: none;
        cursor: pointer
        }

    .tablebottominfo-con-list.active .noborder{
        background: none;
        color: #ffffff
        }

    .tablebottominfo-con-list:hover .moreico{
        opacity: 1
        }

    .tooltipul li{
        line-height: 1.5;
        cursor: pointer;
        padding: 10px 20px
        }

    #elec_table{
        position: relative;
        height: 300px;
        table-layout: fixed;
        }

    .table-head{
        position: absolute;
        z-index: 999;
        height: 30px;
        top: 0
        }

    .bodyBox{
        overflow-y: auto;
        height: 260px;
        position: absolute;
        top: 30px;
        }

    /*设置table-layout:fixed固定宽度，表头和表体需要对齐*/
    table{
        table-layout: fixed;
        }

    /*设置单元格的宽度，可能会出现内容长需要换行的情况 使用white-space:normal，每个单元格都是一样的宽度*/
    #elec_table td{
        width: 30%;
        white-space: normal;
        }

    #elec_table tr{
        cursor: pointer
        }

    .bodyBox::-webkit-scrollbar{
        /*滚动条整体样式*/
        width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        }

    .bodyBox::-webkit-scrollbar-thumb{
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #535353;
        }

    .bodyBox::-webkit-scrollbar-track{
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #ededed;
        }

    .selectlu .active{
        background: #49a4f9;
        color: #ffffff;
        cursor: pointer
        }

    .selectlu .active button{
        color: #ffffff
        }

    .noborder::-webkit-input-placeholder{
        color: #cccccc;

        }

    .ant-table-column-sorter{
        display: flex;
        flex-direction: column;
        margin-top: 1px;
        margin-left: 5px;
        }

    .jiantou{
        color: #999999
        }

    .classname{
        color: #1890ff;
        }

    .fl{
        float: left
        }
</style>
