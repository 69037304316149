<template>
    <div class="rightcontainer">
        <div class="tabletop">
            <div style="display: flex; justify-content: space-between">
                <a-form-model layout="inline" :model="formInline" @submit.native.prevent>
                    <a-form-model-item label="检测项目名称">
                        <a-input v-model="formInline.name" class="w200"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="因子类型">
                        <a-radio-group name="radioGroup" v-model="formInline.factor_type">
                            <a-radio :value="1">
                                单因子
                            </a-radio>
                            <a-radio :value="2">
                                多因子
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="状态" >
                        <a-switch default-checked v-model="formInline.status"/>
                    </a-form-model-item>
                </a-form-model>

                <div class="mt10">
                    <a-button type="primary" @click="adddoClick()">添加检测项目</a-button>
                </div>

            </div>
            <div id="elec_table">
                <div class="table-head">
                    <table class="layui-table" lay-size="sm">
                        <thead>
                        <tr>
                            <th>方法名称</th>
                            <th>执行标准</th>
                            <th>标准文档</th>
                            <th style="width: 150px">是否需要资质</th>
                            <th style="width: 150px">执行</th>
                            <th>检测因子</th>
                        </tr>
                        </thead>
                    </table>
                </div>
                <div class="bodyBox">
                    <table class="layui-table selectlu" lay-size="sm">
                        <tbody></tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="tablebottom">
            <div class="tablebottominfo">
                <div class="tablebottominfotitle">
                    检出限模板
                    <a-button type="primary" size="small" class="fr">添加</a-button>
                </div>
                <div class="tablebottominfo-con"></div>
            </div>
            <div class="tablebottomconcontainer">
                <div id="elec_table" style="height: 420px">
                    <div class="table-head">
                        <table class="layui-table" lay-size="sm">
                            <thead>
                            <tr>
                                <th>检测因子</th>
                                <th>化学式</th>
                                <th>类型</th>
                                <th>范围上限</th>
                                <th>范围下限</th>
                                <th>单位</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="bodyBox" style="height: 385px"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Creatmoban from "../creatmoban/creatmoban";

    export default {
        props: ['create_do', 'reload'],
        components: {
            Creatmoban
        },
        data() {
            return {
                formInline: {},
            }
        },
        mounted() {
        },
        methods: {
            adddoClick() {
                let data = {
                    name: this.formInline.name,
                    pid: this.$route.params.id,
                    type: 1,
                    factor_type:Number(this.formInline.factor_type),
                    status: this.formInline.status ? '1' : '2',
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.$api('Adddetitem'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            // layer.msg('创建成功')
                            this.$router.push('/taskability/taskability/' + response.data.id)
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

        }
    }
</script>
<style scoped>

    .mt10{ margin-top: 10px}

    .ml10{ margin-left: 10px}

    .rightcontainer{ }

    .tabletop{ background: #FAFAFA; padding: 5px 5px 0px 5px; }

    .tablebottom{ width: 100%; display: flex; flex-direction: row; margin-top: 10px; }

    .tablebottominfo{ width: 250px;}

    .tablebottomconcontainer{ flex: 1;}

    .tablebottominfo{ background: #FAFAFA; padding: 5px}

    .tablebottominfotitle{ font-weight: bold}

    .fr{ float: right}

    .tablebottominfo-con{ background: #ffffff; margin-top: 5px; height: 370px;overflow-y: auto }

    .tablebottominfo-con-list{ width: 100%; display: flex; justify-content: space-between; border-bottom: 1px solid #eeeeee; padding: 5px;}

    .tablebottominfo-con-list.active{ background: #49a4f9; color: #ffffff}

    .tablebottominfo-con-list-name{ width: 80%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis; }

    .moreico{ transform: rotate(-90deg); opacity: 0.8; font-size: 1.5rem; transition: 0.3s all; margin-top: 5px; cursor: pointer}

    .tablebottominfo-con-list-name .noborder{ white-space: nowrap;overflow: hidden;text-overflow: ellipsis; width: 100%; }

    .tablebottomconcontainer{ margin-left: 10px; margin-top: -10px;}

    .w100{ width: 100px}

    .noborder{ border: none; background: none; cursor: pointer }

    .tablebottominfo-con-list.active .noborder{ background: none; color: #ffffff }

    .tablebottominfo-con-list:hover .moreico{ opacity: 1 }

    .tooltipul li{ line-height: 1.5; cursor: pointer; padding: 10px 20px}

    #elec_table{
        position: relative;
        height: 300px;
        table-layout: fixed;
        }

    .table-head{ position: absolute; z-index: 999; height: 30px; top: 0 }

    .bodyBox{
        overflow-y: auto;
        height: 260px;
        position: absolute; top: 30px;
        }

    /*设置table-layout:fixed固定宽度，表头和表体需要对齐*/
    table{
        table-layout: fixed;
        }

    /*设置单元格的宽度，可能会出现内容长需要换行的情况 使用white-space:normal，每个单元格都是一样的宽度*/
    #elec_table td{
        width: 30%;
        white-space: normal;
        }

    #elec_table tr{ cursor: pointer}

    .bodyBox::-webkit-scrollbar{
        /*滚动条整体样式*/
        width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        }

    .bodyBox::-webkit-scrollbar-thumb{
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #535353;
        }

    .bodyBox::-webkit-scrollbar-track{
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #ededed;
        }

    .selectlu .active{ background: #49a4f9; color: #ffffff; cursor: pointer}

    .selectlu .active button{ color: #ffffff}

    .noborder::-webkit-input-placeholder{
        color: #cccccc;

        }

</style>
